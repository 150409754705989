import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";

export default {
  data: () => ({
    contactusList: [],
    showLoader: false,
    sortColumn: "name",
    selectedRows: [],
    selectedId: 0,
    sortDirection: "asc",
    paginate: {
      page_count: 1,
      selected: 1,
      per_page: 50,
      current_page: 1,
      last_page: 0,
    },
    recordTakeOptions: [25, 50, 100, 200],
  }),
  mixins: [paginationMixin],
  computed: {
    selectAllList() {
      if (this.contactusList.length > 0) {
        return (
          _.where(this.contactusList, {
            isSelected: true,
          }).length === this.contactusList.length
        );
      }
      return false;
    },
    hasContactShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "List" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "List" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "List" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasContactDeletePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Delete" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Delete" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Delete" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
  },
  components: { "header-list": headerList, PaginationComponent },

  methods: {
    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        this.contactusList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      this.modal7;
      _vm.axios
        .post("/contact-us-deletes", {
          ids: ids,
        })
        .then(function (response) {
          _vm.getAll();
          _vm.selectedId = 0;
        });
    },
    search(value) {
      this.getAll();
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    applySort(columnName) {
      if (this.sortColumn === columnName) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      this.getAll();
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      this.axios
        .get("/contact-us" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          let data = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          _vm.contactusList = data;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function (error) {});
    },
    handleCheck(e) {
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          this.selectedRows = this.contactusList.map(
            (item) => "checkbox" + item.id
          );
          this.contactusList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          this.selectedRows = [];
          this.contactusList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          this.selectedRows.push(e.target.id);
        } else {
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getAll();
  },
};
